/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
.main-niceadmin {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
}
@media (max-width: 1199px) {
  .main-niceadmin {
    padding: 20px;
    background-color: rgb(221, 194, 194) !important;
  }
}
.alert{
  width: 400px;
  margin: auto;
}
